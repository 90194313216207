.header-container {
	padding-bottom: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--mantine-font-family, inherit);
}

.header-title {
	text-align: center;
	font-size: 0.875rem;
	font-weight: 600;
	color: var(--mantine-gray-7, #666);
	margin-right: 0.6rem;
	line-height: 1.2;
	white-space: nowrap;
}

.header-count {
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	color: var(--mantine-gray-8, #444);
	margin-right: 0.2rem;
	line-height: 1.2;
}

.header-label {
	text-align: center;
	font-size: 0.875rem;
	font-weight: 600;
	color: var(--mantine-gray-7, #666);
	line-height: 1.2;
}
