.tab {
	font-weight: 600;
	font-size: var(--mantine-font-size-md);
	color: var(--mantine-color-grey-5);
	margin-top: -1px;
}

.activeTab {
	color: var(--mantine-color-dark-9);
}
