.cell-edited {
    border: 1px solid red;
    pointer-events: auto;
    opacity: 1;
}

.cell-normal {
    border: none;
    pointer-events: auto;
    opacity: 1;
}

.row-disabled {
    background-color: #f0f0f0;
    pointer-events: none;
    opacity: 0.6;
}

.row-normal {
    background-color: white;
    pointer-events: auto;
    opacity: 1;
}

.cell-edited {
    border: 1px solid red;
}

.cell-normal {
    border: none;
}

.fund-table{
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin: auto;

}