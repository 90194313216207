
/* delete modal css */
.delete-modal .mantine-Modal-body {
    padding: 0;
}

.delete-modal .cancel .mantine-Button-inner {
    display: unset;
}

/* warning modal css */
.warning-modal .mantine-Modal-body {
    padding: 0;
}