.fund-group-textInput .mantine-Input-input {
    width: 26.25rem;
    height: 2.5;
    border-radius: 0.25rem;
    border: solid 1px #7d7d7d;
    background-color: #fff;
}

.fund-group-textInput .mantine-TextInput-label {
    width: 9.625;
    height: 1rem;
    font-size: 0.813rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    text-align: left;
    margin-bottom: -0.2rem;
    color: rgba(0, 0, 0, 0.85);
}