.labels {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

.labels-space {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 2.5rem;
}

.labels-space-type {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 2.5rem;
}

.labelfont {
	font-weight: 590;
	margin-right: 2.85rem;
	font-size: 0.813rem;
	line-height: 1rem;
}

.maincontainer {
	padding: 3rem;
}

.labelfontId {
	font-weight: 590;
	margin-right: 4.5rem;
}

.labeltransactionId {
	font-weight: 590;
	margin-right: 1.85rem;
}

.labelassetId {
	font-weight: 590;
	margin-right: 4.2rem;
}

.selectlabels {
	width: 13.625rem;
	height: 1.688rem;
	background-color: #efefef;
	border-color: #efefef;
}

.checkbox {
	display: flex;
	margin-top: 2rem;
	margin-left: 5.8rem;
}

.assettype {
	margin-right: 2.1rem;
	font-weight: 590;
}

.labeltype {
	margin-right: 2.3rem;
	font-weight: 590;
}

.datepicker {
	margin-top: 2rem;
	display: flex;
	align-items: center;
}

.datepicker > * {
	margin-right: 10px;
}

.datelabel {
	margin-left: 5.5rem;
	margin-right: 1.8rem;
	margin-top: -0.2rem;
	font-size: 0.813rem;
}

.datelabelTo {
	margin-left: 4.5rem;
	margin-right: 1.8rem;
	margin-top: -0.2rem;
	font-size: 0.813rem;
}

/* .m-8fb7ebe7 {
    min-height: 1.688rem;
    height: 1.688rem;
    background-color: #efefef;
    border: 1px solid #0000000D;
    border-radius: 0.375rem;
    text-align: center;
} */

.date {
	font-weight: 590;
	font-size: 0.813rem;
	margin-top: 0.3rem;
}

.buttonspace {
	margin-left: 2rem;
}

.buttonsection {
	margin-top: 2rem;
	display: flex;
	justify-content: flex-end;
}

[data-mantine-color-scheme] .m-26063560:checked {
	--_checkbox-bg: var(--mantine-color-violet-6);
	--_checkbox-bd-color: var(--mantine-color-gray-4);
}

.exportbutton {
	text-align: right;
}

.calender-start {
	margin-top: 2.3rem;
	display: flex;
}

.error-message {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5rem;
}

.iconlayout {
	padding-left: 1rem;
	padding-top: 1rem;
}

.loader {
	margin-top: 2rem;
}

.dateforholding {
	margin-right: 5.65rem;
	font-weight: 590;
}

.main-content {
	padding-top: calc(1.875rem - 0.9rem);
	/* height: 100%; Leave this commented out for testing  */
}

.calender-start-holding {
	margin-top: 1rem;
	display: flex;
}

.button-style {
	border: 1px solid lightgrey;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.mantine-Input-input {
	text-align: center;
	border: 1px solid #0000000d;
	background-color: #eeeeee;
	font-size: 13px;
	line-height: 22px;
	height: 22px;
}
.search-input .mantine-Input-input {
	text-align: left;
}

.table-alignment {
	padding-left: 3.4rem;
	padding-right: 3.4rem;
	z-index: 0;
}

.flex {
	/* display: flex; */
	height: 10vh;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.shrink-0 {
	flex-shrink: 0;
}

.grow {
	flex-grow: 1;
}

.h-screen {
	height: 100%;
}

.overflow-hidden {
	overflow: hidden;
}

.main-content {
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;
}

.main-content::-webkit-scrollbar {
	width: 8px;
}

.main-content::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.main-content::-webkit-scrollbar-track {
	background-color: transparent;
}

.get-data-content {
	z-index: 2;
	overflow-y: auto;
	position: relative;
	background: white;
	box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
}

.dragging .get-data-content {
	z-index: 2;
}

.heightcheck .mantine-Input-input {
	height: 0.5rem;
	font-size: 13px;
}

.opened-preset-toggle {
	position: fixed;
	width: 20%;
	height: 100%;
	transition: width 0.3s ease;
}

.closed-preset-toggle {
	width: 0;
	transition: width 0.3s ease;
}

.main-content-box {
	transition: margin-left 0.3s ease;
}

.shift-right {
	padding-left: calc(18.75rem + 1.875rem);
	transition: padding-left 0.3s ease;
}

.overflow-x-auto {
	max-width: 100%;
	overflow-x: auto;
}
/* .all-fund-query-container{
    height: 100%;
} */

.all-fund-query-container .calender-container {
	width: 238px;
}

.all-fund-query-container .calender-container .mantine-TextInput-input {
	height: 30px;
	min-height: 30px;
	font-size: 13px;
}

.mantine-Button-root {
	border-radius: 0.375rem;
}

.input::placeholder {
	font-size: 13px;
}

.checkbox-text {
	font-size: 0.812rem;
	line-height: 1.23;
	color: rgba(0, 0, 0, 0.85);
}

.fund-query-2 {
	display: flex;
	flex-direction: column;
	margin-top: -0.33rem;
}

.fund-query-2 .calender-start {
	flex-direction: column;
	gap: 1rem;
}

.fund-query-2 .calender-start .mantine-TextInput-wrapper {
	width: 11.5rem;
}

.fund-query-2 .datelabel {
	margin-left: 1.5rem;
}

.fund-query-2 .buttonsection {
	margin-right: unset;
	justify-content: space-between;
	margin-top: 1.7rem;
}
.savepreset-modal .mantine-TextInput-input {
	text-align: left;
}
.fund-tabs .controls {
    width: 371px;
}
.chat-tabs .controls {
    width: 500px;
}