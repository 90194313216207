.position-summary-table .ag-header-row {
  height: 2.1rem;
  min-width: 2.1rem;
}

.position-summary-table .ag-header-cell-label {
  justify-content: center;
}

.position-summary-table .ag-header-cell-resize::after {
  background-color: #b4b4b4;
} 


.position-summary-chart {
  border: 1px solid #E0E0E0;  
}
.position-summary-box.simple {
  padding: 10px, 30px, 0px, 30px;
}
.position-summary-box.selected {
  background-color: #F9F7FF;
}
.position-summary-button.simple {
  padding: 0px 6px;
  border: 1px solid #E0E0E0;
  color: black;
  background-color: white;
}
.position-summary-button.selected, .position-summary-button.simple:hover, .position-summary-button.selected:hover {
  background-color: #ECE3FF;
  color: black;
  padding: 0px 6px;
  border: 1px solid #E0E0E0;
}
.position-summary-graph-area {
  background-color: #FFFFFF;
}

.stat-graph{
  margin-top: 1rem;
}