.dropfolder .mantine-Input-input{
    background-color: #FAFAFA;
    text-align: left;
    border: 1px solid #FAFAFA;
    margin-bottom: 0.2rem;
}

.folder-wrapper{
    margin-left: 1.5rem;
}

.context-menu-container {
    position: absolute;
    z-index: 1000;
    margin-top: 3.4rem;
    border-radius: 0.625rem;
  }
  
  .inputfolder .mantine-Input-input {
    text-align: left;
    border-radius: 4px;
    padding: 0.6rem;
    border: 1px solid transparent; 
    transition: border-color 0.3s ease; 
    margin-left: 0.2rem;
    background-color: #c7c7c7;  
  }
  
  .inputfolder .mantine-Input-input:focus {
    border-color: #651FFF;
  }

.preset-search input::placeholder {
  font-size: 13px;
}
.saved_chat .label-text{
  margin-left: 20px;
  color:red;
}