.demo-form .mantine-TextInput-input,
.demo-form .mantine-Select-input
{
  background-color: #FFFFFF;
  border: 1px solid #b4b4b4;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.8rem;
  height: 1.75rem;
  min-height: 1.75rem;
  width: 11.5rem;
  text-align: left;
}

.demo-form .mantine-Select-wrapper {
  width: 11.5rem;
}

.demo-form .mantine-Select-dropdown {
  border-radius: 10px;
}

.mantine-ScrollArea-viewport {
  padding-right: 0px;
}

.mantine-Select-option {
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
  padding: 0.1rem 0.25rem;
  margin-bottom: 0.1rem;
}

.mantine-Select-option .demo-select-option {
  justify-content: flex-start;
}

.mantine-Select-option:hover, .mantine-Select-option[data-combobox-active=true] {
  background-color: #eee;
}

.mantine-Select-section {
  background-color: #b4b4b4 ;
  margin: 6px 6px 6px 0px;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 4px;
  color: #fff;
}

.demo-form .cashstyle::placeholder, .demo-form .mantine-TextInput-input::placeholder, .demo-form .mantine-Select-input::placeholder {
  color: #7d7d7d;
}

.demo-form .cashstyle:focus, .demo-form .mantine-TextInput-input:focus, .demo-form .mantine-Select-input:focus {
  border: 1px solid #5b5b5b;
}

.page-dropdown .mantine-Select-input {
  background-color: white;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
  box-shadow: 0 0 0 0.03125rem rgba(0, 0, 0, 0.05), 0 0.03125rem 0.15625rem 0 rgba(0, 0, 0, 0.3);
  padding: 0.1875rem 0.1875rem 0.1875rem 0.4375rem;
}

.demo-form {
  margin-top: 84px;
}