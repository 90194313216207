.container {
	height: 100vh;
	background-color: #f9f9f9;
}

.sidebar {
	transition: width 0.3s ease;
	min-height: 100%;
	overflow: hidden;
	position: relative;
	background-color: #f2f2f2;
}

.sidebar--closed {
	width: 30px;
}

.sidebarContent {
	/* width: 288px; */
	height: 100%;
	background-color: #e0e0e0;
	transition: opacity 0.2s ease;
}

.sidebarContent--visible {
	opacity: 1;
}

.sidebarContent--hidden {
	opacity: 0;
}

.gridContainer {
	width: 100%;
	margin: 0;
	padding: 0 20px 0 10px;
}

.searchSection {
	margin: 40px 10px 15px -10px;
}

.navigationSection {
	margin-bottom: 1px;
}

.chatSection {
	margin-bottom: 0px;
}

.sectionTitle {
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 12px;
}

.toggleButton {
	position: absolute;
	top: 4%;
	right: -3px;
	transform: translateY(-50%);
	background: none;
	padding: 10px;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
}

.chatList a {
	margin-top: -20px;
}

.chatSection {
	margin-left: -12px;
	margin-top: -20px;
}

.chatSectionConatainer {
	max-height: 45rem;
	overflow-y: auto;
	padding-left: 1rem;
}
