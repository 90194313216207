.forecast-header .ag-header-cell-label {
	justify-content: center;
}

.cash-exposure-tables-container {
	padding-bottom: 40px;
}

.equitize-table {
	width: 52rem;
	padding: 1rem;
	min-height: 12rem;
}

.equitize-table .ag-theme-quartz::after {
	content: none;
}