* {
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.selected-item {
	text-align: center;
}

.controls-container {
	--highlight-width: auto;
	--highlight-x-pos: 0;
	display: flex;
	/* margin: 14px 25px 0; */
}

.controls {
	display: inline-flex;
	justify-content: space-between;
	background: #f2f2f2;
	box-shadow: 0px 0.5px 2.5px 0px #0000004d;
	border-radius: 0.3125rem;
	width: 34.75rem;
	height: 1.375rem;
	height: 22px;
	margin: auto;
	overflow: hidden;
	position: relative;
}
.fund-tabs .controls {
	width: 371px;
}
.fund-order-tabs{
	padding-left: 0;
	padding-right: 0;
	margin-right: 35px;
	margin-top:4px;
}
.controls input {
	opacity: 0;
	margin: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	cursor: pointer;
	height: 100%;
}

.segment {
	width: 11.5rem;
	height: 1.25rem;
	position: relative;
	text-align: center;
	z-index: 1;
}

.segment label {
	cursor: pointer;
	display: block;
	transition: color 0.5s ease;
	color: #818181;
	margin-top: 0.1rem;
}

.segment.active label {
	margin-top: 0.1rem;
	color: black;
	font-weight: 400;
}


.controls::before {
	content: "";
	background: white;
	border-radius: 5px;
	border: 0.5px solid lightgrey;
	width: var(--highlight-width);
	transform: translateX(var(--highlight-x-pos));
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	z-index: 0;
}

.controls.ready::before {
	transition: transform 0.3s ease, width 0.3s ease;
}

.separator {
	margin-left: -4px;
	margin-right: -7px;
	margin-top: 0.12rem;
	color: #c7c7c7;
}

.preset-wrapper {
	z-index: 2;
	min-height: 100vh;
	height: 100%;
}

.position-buttons {
	border-radius: 6px;
	box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
	height: 1.375rem;
}

.controls-container.investment-tabs .controls {
	width: 370px;
}
