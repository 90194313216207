.no-issue-button-style {
	border: 1px solid lightgrey;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.table-collapsable-sidebar {
	position: sticky;
	top: 0;
	width: 194px;
	margin-top: 19.7rem;
	background-color: #fff;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
	z-index: 1;
	border: 1px solid #bdc3c7;
	border-radius: 0.125rem;
	padding: 0.2rem 0.8rem 0.8rem 0.8rem;
}

.table-collapsable-sidebar.collapsed {
	width: 32px;
}

.tables {
	padding: 1.82rem 0 1.82rem 1.82rem;
	margin-top: 10px;
}

.counters-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 1.8rem;
}

.counters-grid > div {
	flex: 1 calc(25% - 30px);
	max-width: calc(34% - 30px);
}

.card-container {
	margin: 1.25rem 1.8rem 1.875rem;
	gap: 1.8rem;
}

@media (max-width: 1024px) {
	.counters-grid > div {
		flex: 1 1 calc(50% - 30px);
		max-width: calc(50% - 30px);
		margin-bottom: 1rem;
	}
}

@media (max-width: 480px) {
	.counters-grid > div {
		flex: 1 1 calc(100% - 30px);
		max-width: calc(100% - 30px);
	}
}
@media (max-width: 768px) {
	.counters-grid {
		margin: 0 1rem;
	}

	.counters-grid > div {
		flex: 1 1 calc(100% - 20px);
		max-width: calc(100% - 20px);
	}

	.card-container {
		margin: 0.5rem 1rem;
	}
}
