.preset-leftNav {
	background-color: #e0e0e0;
	padding: 0.5rem 0.5rem;
	padding-right: 0;
	width: 100%;
	min-height: 100vh;
}

.preset-leftNav-hidden {
	padding-top: 0.5rem;
	height: 100%;
	background-color: #e0e0e0;
	position: absolute;
	right: 0;
	cursor: pointer;
	transition: all 0.3s ease; 
}
.preset-leftNav-hidden.collapsed {
	position: static; 
}
.fund-query-2 .mantine-Input-input {
	background-color: #fff;
}

.dropfolder .mantine-Input-input {
	background-color: transparent;
	border-color: transparent;
}

.fund-query-2 .labelfont {
	margin-right: unset;
	font-weight: 600;
}
.preset-option2-container {
	position: relative;
}
.preset-option2-container .folder-wrapper {
	margin-left: 0;
	padding: 6px;
}

.preset-option2-container .mantine-Accordion-item {
	background-color: unset;
}

.preset-option2-container .mantine-Accordion-chevron {
	margin-inline-start: -0.875rem;
	margin-inline-end: 0.5rem;
}

.preset-option2-container .mantine-Accordion-label {
	padding-top: 0;
	padding-bottom: 0;
}

.preset-option2-container .mantine-Accordion-content {
	padding: 0;
	margin-top: -0.3rem;
}

.preset-option2-container .presets-list input:disabled {
	color: "unset";
}
.presets-list {
	margin-right: 20px;
}
.fund-query-container {
	width: 100%;
	padding: 19px 0px 0 26px;
	background-color: #e0e0e0;
	border-radius: 6px;
	align-items: center;
	margin: 0 auto;
	margin-top: -38px;
	margin-left: -7px;
}

.preset-leftNav {
	max-width: 100%;
	width: 278px;
}
.button-container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}

.get-data-button,
.save-preset-button {
	width: 220px;
	height: 30px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
}

.fund-query-container input {
	background-color: #fff;
	border: none;
	height: 30px;
	border-radius: 4px;
}

.labelfont {
	font-weight: 600;
}
.buttonsection {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	width: 100%;
}
.button-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.get-data-button,
.save-preset-button {
	width: 220px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px 7px;
	border-radius: 6px;
	font-size: 13px;
}

.get-data-button {
	background-color: #651fff;
	color: white;
	border: none;
}

.save-preset-button {
	background-color: white;
	color: #5b5b5b;
}
.save-preset-button:hover {
	background-color: #ece7f7;
	color: black;
	border: none;
}

.get-data-button:hover {
	background-color: #ece7f7;
	color: #5b5b5b;
}
.fund-query-container .mantine-InputWrapper-root {
	margin-bottom: 0;
	width: 95%;
}

.fund-query-container .mantine-Input-input {
	margin-bottom: 0;
}
.flex-inputs-container {
	gap: 8px;
}
.mantine-InputWrapper-root {
	margin-bottom: 0;
}
.mantine-TextInput-root,
.mantine-Select-root {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding: 0;
}
input::placeholder,
textarea::placeholder {
	text-align: left;
}
.mantine-Select-input {
	border: none;
	box-shadow: none;
}
.checkbox-smart-query .mantine-Checkbox-inner svg {
	color: #565658 !important;
	width: 12px;
	height: 13px;
	margin-left: 2px;
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.custom-checkbox {
	width: 16px;
	height: 18px;
}
.custom-checkbox input[type="checkbox"]:checked + .mantine-Checkbox-inner {
	background-color: #651fff;
	border: 1px solid #651fff;
}

.mantine-Checkbox-body {
	gap: 4px;
}

.mantine-Checkbox-label {
	padding-inline-start: 0;
	padding-inline-end: 0;
}
.fund-query-container .mantine-TextInput-input {
	text-align: left;
}
.preset-leftNav-hidden button {
	margin-top: 8px;
	margin-right: 6px;
}
.custom-checkbox-smart-query {
	border: 1px solid #000000d9 !important;
	height: 16px !important;
	width: 16px !important;
	border-radius: 3px !important;
	padding: 8px !important;
	background-color: white !important;
}
.favorites-icon {
	margin-right: 10px;
	font-size: 16px;
}
.folder-icon {
	margin-right: 10px;
	font-size: 16px;
}
button.custom-disabled {
	background-color: #f0f0f0;
	color: #b4b4b4;
	cursor: not-allowed;
	opacity: 0.6;
}
.document-wrapper {
	cursor: pointer;
	margin-left: 2.775rem;
	margin-top: 5px;
}
 .document-name:hover{
	background: #f0f0f096;
}
/* .document-wrapper.highlighted {
	background-color: #651fff24;
	animation: highlight-fade 2s ease-in-out;
}
@keyframes highlight-fade {
	0% {
		background-color: #651fff24;
	}
	100% {
		background-color: transparent;
	}
} */
.document-wrapper .inputpreset .mantine-Input-input{
	border-color: #651FFF;
	background-color: #c7c7c7;
	margin-left: 0;
	text-align: left;
	width: 92%;
}
.document-wrapper .document-name{
	width: 92%;
	padding: 0 10px
}

.folder-list{
	margin-left: 4px;
    margin-top: 9px;
}
.folder-list-acc{
	padding: 0!important;
}
.query-form{
	z-index: 1;
}
.mantine-Select-input {
  text-align: left !important;
  padding-left: 10px;
}