.date-filter-container {
  margin:  2rem 4rem;
  justify-content: center;
}

.date-card {
  border-radius: 0rem;
  padding: 0.5rem 2.0625rem;
  text-align: center;
  border-color: #b4b4b4;
  border-width: 0.03125rem;
  color: #5b5b5b;
  line-height: 1.23;
  min-width: 10.5rem;
  background-color: #FAFAFA;
}

.date-card.selected, .date-card:hover {
  background-color: #EEE7FC;
}

.date-card:not(.date-card.selected) { 
  cursor: pointer;
}

.date-card:first-child {
  border-top-left-radius: 0.875rem;
  border-bottom-left-radius: 0.875rem;
}

.date-card:last-child {
  border-top-right-radius: 0.875rem;
  border-bottom-right-radius: 0.875rem;
}

.date-card > div > span {
  margin-left: 0.5rem;
}