.datebutton {
	font-weight: 400;
}

.day {
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.selectedicon {
	border-radius: 6px;
	height: 2rem;
	width: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cancelbutton {
	display: flex;
}

.calender-container {
	position: relative;
}

.calender {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	border-radius: 8px;
	position: absolute;
	z-index: 9;
	left: 0;
	top: 30px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	padding: 16px;
	border: 1px solid #00000069;
}
.calender > .buttons {
	display: flex;
	flex-direction: column;
	width: 179px;
	padding: 0.5rem;
	padding-left: 0;
	height: 100%;
	justify-content: space-between;
}

.calender > .buttons .mantine-Button-root {
	background-color: transparent;
	padding: 0px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.85);
}

.calender > .buttons .mantine-Button-root:hover,
.calender > .buttons .mantine-Button-root.active,
.calender .mantine-DatePicker-day:hover,
.mantine-DatePicker-calendarHeader .mantine-DatePicker-calendarHeaderControl:hover,
.mantine-DatePicker-calendarHeader .mantine-DatePicker-calendarHeaderLevel:hover {
	background-color: #eeeeee;
}

.calender > .date-picker {
	flex-grow: 1;
	padding: 8px;
	padding-bottom: 0px;
	height: 98%;
	overflow: auto;
}

.cashcalender {
	z-index: 999;
}

.cashstyle {
	height: 3.5rem;
	width: 18.375rem;
	text-align: center;
	border: 1.5px solid darkgray;
	background-color: rgb(255 255 255 / 0%);
	border-radius: 1vw;
	font-weight: 400;
	font-size: 1.25rem;
	margin-top: 0.2rem;
}

.mantine-DatePicker-day {
	color: rgba(0, 0, 0, 0.85);
	font-size: 15px;
	font-weight: 500;
	margin-left: 3px;
	/* Change color to your desired color */
}

.mantine-DatePicker-day[data-outside="true"] {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
	color: transparent;
}

.mantine-DatePicker-calendarHeaderLevel.mantine-UnstyledButton-root {
	font-weight: 700;
	font-size: 15px;
}

.mantine-DatePicker-calendarHeaderLevel {
	text-align: left;
	font-weight: 700;
}

.mantine-DatePicker-weekday {
	color: lightgrey;
}

.calender-input .mantine-TextInput-input {
	cursor: pointer;
}

.mantine-DatePicker-calendarHeader {
	justify-content: center;
}

.mantine-DatePicker-calendarHeader .mantine-DatePicker-calendarHeaderLevel {
	padding-left: 10px;
	padding-right: 10px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 1.125rem;
}

.mantine-DatePicker-calendarHeader .mantine-DatePicker-calendarHeaderControl,
.mantine-DatePicker-calendarHeader .mantine-DatePicker-calendarHeaderLevel {
	flex: auto;
	flex-grow: 0;
	color: rgba(0, 0, 0, 0.85);
}
