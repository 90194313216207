.login-form {
	width: 323px;
	margin-bottom: 107px;
	margin-top: 9px;
}
.login-logo {
	width: 12.1875rem;
	margin: 102px 154px 38px 145px;
}
.mantine-Grid-inner {
	width: unset;
}

.login-form .mantine-TextInput-input {
	background-color: #fff0;
	border: 1px solid #b4b4b4;
	border-radius: 0.625rem;
	color: rgba(0, 0, 0, 0.85);
	font-size: 20px;
	height: 56px;
	padding: 20px;
	width: 294px;
}

.login-form .mantine-TextInput-input::placeholder {
	color: #7d7d7d;
}

.login-form .mantine-TextInput-input:focus {
	border: 1px solid #5b5b5b;
}

.login-form .mantine-Button-root {
	border-radius: 0.625rem;
	width: 294px;
}
.center-placeholder input::placeholder {
	text-align: center;
}
