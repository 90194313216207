.spendCashCell {
    background-color: #FFB4B0;
  }

  .spendcash{
    font-weight: 590;
  }

  .spendcashstyles{
    text-align: right;
    justify-content: flex-end;
  }