/* HomePage.css */

.home-page {
  display: flex;
  justify-content: space-around;
  margin: 20px; 
}

.card {
  width: 30%;
  padding: 20px;
  border-radius: 3vw;
  box-shadow: 0 4px 8px var(--mantine-color-dark-4);;
}

.tableTitle {  
  font-weight: 600;
}

.containerStyle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardStyle{
  padding: var(--mantine-spacing-md);
  height: 8rem;
}

.agStyle{
  margin-top: 4rem;
  margin-bottom: 2rem;
  align-self: center;
  width: 97%;
}

.inPill{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.3vw;
  border-radius: 0.625rem;
  font-size: 0.688rem;
  width: 2rem;
  height: 1rem;
  font-weight: 700;
}

.outPill {
display: inline-flex;
align-items: center;
justify-content: center;
padding: 0.3rem;
border-radius: 0.625rem;
font-size: 0.688rem;
width: 2.813rem;
height: 1rem;
font-weight: 700;
}

.gapTables  {
  margin-top: 2rem;
}

.cardcomp{
  display: flex;
  justify-content: space-between;
}

.marketTitle {
  margin-right: var(--mantine-spacing-md);
  font-weight: 700;
  font-size: var(--mantine-spacing-md);
}

.fundStyle {
  font-size: var(--mantine-spacing-lg);
  font-weight: 700;
}
 
.headerStyle {
  height: 60;
  display: flex;
  align-items: center;
  border-bottom: "1px solid lightgrey";
  margin-top: 1rem;
  display: flex; 
  justify-content: space-between;
}

.maindiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;
}   
 
.iconupdown{
  margin-left: var(--mantine-spacing-xl);
  margin-bottom: -0.4rem ;
}

.tablelabels{
  display: flex;
  flex-direction: column;
}

.iconcollapse{
  margin-left: 1rem;
  margin-bottom: -0.5rem;
}

.error-message{
 margin-top: 6rem;
 align-items: center;
 text-align: center;
}

.mainContent {
  margin-top: 1rem;
  overflow-x: auto;
  padding-left: 1.9rem;
  padding-right: 1.9rem;
}



 

