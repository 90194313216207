.discard-button {
    width: 9.1875rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    /* margin: 0.5rem; */
}

.discard-button:hover {
    width: 9.1875rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    /* color: black; */
    /* margin: 0.5rem; */
}

.update-button {
    width: 6.6875rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    /* margin: 0.5rem; */

}

.create-workitem-button {
    width: 10.375rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    color: white;
    margin-left: 1rem;
}

.create-workitem-disable-button {
    width: 166px;
    height: 30px;
    margin-left: 1rem;
    opacity: 0.4;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    background-color: #651fff
}

.discard-recon-button {
    width: 14.0625rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    margin: 0.5rem;
    color: #5b5b5b;
    padding: 0;
}

.discard-recon-button:hover {
    color: #5b5b5b;
}

.create-asset-button {
    width: 6.6875rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
}

.create-new-asset-button {
    width: 9.9375rem;
    height: 1.375rem;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
}

.button:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
}

.refresh {
    width: 9.437rem;
    height: 1.375rem;
    border-radius: 0.375rem;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    font-size: 0.813rem;
    font-weight: 600;
    color: black;
}

.contact-support {
    width: 13.313rem;
    height: 1.375rem;
    background-color: transparent;
    color: black;
    text-decoration: underline;
    font-size: 0.8125rem,
}