.iconStyle {
  height: 100%;
  margin-top: 1rem;
}

.gridStyle{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: -0.8rem; */
};

.actionTextStyle {  
  font-size: 0.8rem;
}

.titleStyle{
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.fundfigure {
  text-align: right !important;
  margin-top: -0.2rem;
}  

.rightAligned {
  text-align: right;
}

.fundspace{
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
}


.dataForCardsStyle{
  display: flex;
  flex-direction: row;
  margin-top: -2rem;
  justify-content: center;
  
}

.borderRight {
  position: relative; 
}

.borderRight::after {
  content: ''; 
  position: absolute;
  top: 0.5rem; 
  bottom: -0.4rem; 
  right: 0; 
  width: 0.8px; 
  background-color: var(--mantine-color-gray-4); 
}

.borderRight::after {
content: ''; 
position: absolute; 
top: 0.5rem; 
bottom: -0.4rem; 
right: 0; 
width: 0.8px; 
background-color: var(--mantine-color-gray-4);
}

.borderRight:last-child::after {
background-color: transparent; 
}

.borderRightTwoValues:first-child {
border-right-color: transparent;
}

.borderRightTwoValues:before {
content: "";
position: absolute;
top: 0;
bottom: 0;
left: 50%;
width: 0.1px;
background-color: var(--mantine-color-gray-4);
margin-top: 0.5rem;
margin-bottom: -0.5rem;
transform: translateX(6.2rem);
}

.borderRightTwoValues:last-child:before {
background-color: transparent;
}

.dataForCardsMap{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  font-weight: 700;
  width: 6.9375rem;
  box-sizing: border-box;
  position:relative;
  margin-top: 2rem;
  height: 3.50125rem;
}

/* .verticalLineStyle{
  position:absolute;
  top: 32;
  bottom: 22;
  left: 0;
  width: 0.5px;
  margin: auto;
} */

.textNum {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: -2rem;
}

.titleReadyStyles{
  font-size: 1.25rem;
 margin-bottom: -0.3rem;
}

.labeltextstyle{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.labelInText {
border-radius: 0.625rem;
font-size: 0.688rem;
font-weight: 700;
width: 2rem;
height: 1rem;
display: flex;
align-items: center;
justify-content: center;
}

.labelOutText {
border-radius: 0.625rem;
font-size: 0.688rem;
font-weight: 700;
width: 2.813rem;
height: 1rem;
display: flex;
align-items: center;
justify-content: center;
}

.label2text{
font-size: 0.8rem;
font-weight: "700";
margin-top: -0.3rem;
width: 6.5rem;
line-height: 0.875rem;
}

.aligncardtext{
text-align: center; 
margin-top: 0.5rem;
max-width: 7rem;
}

.fontfigure{ 
font-size: 2.7rem; 
}

.card {
display: flex;
border-radius: 1.125rem;
flex-direction: column;
align-items: center;
cursor: pointer;
height: 10rem;
width: 23.3125;
transition: background-color 0.3s ease;
}