.mantine-NavLink-label {
    color: black;
    font-size: 0.8125rem;
}

.navlink-account {
    border-radius: 0.375rem;
    height: 1.7rem;
    width: 15.0625;
}

.navlink-account:hover {
    background-color: unset;
    color: white;
}

.mantine-NavLink-root[data-active]{
    background-color: #cecece;
}

.account .mantine-TextInput-input:disabled {
    background-color: #dfdfdf;
    color: black;
    border: none;
    text-align: left;
    padding-left: 1.25rem;

} 

.value-text-input .mantine-TextInput-input, .language-select{
    width: 10rem;
    height: 1.5625rem;
    margin: 10px 0 0;
    border-radius: 4px;
    border: solid 0.5px #eee;
    background-color: #fff;
    text-align: left;
}
 .language-select .mantine-Select-input{
    background-color: #fff !important;
    border: solid 0.5px #eee !important;
 }


.decorator-text-input .mantine-TextInput-input{
    width: 20rem;
    height: 1.5625rem;
    margin: 10px 0 0;
    border-radius: 4px;
    border: solid 0.5px #eee;
    background-color: #fff;
    text-align: left;
}

.margin-log-out-navlink{
    margin-top: 3rem;
    padding-left: 1.5rem;
}
.margin-default-navlink{
    margin-top: 0.2rem;
    padding-left: 1.5rem;
}
