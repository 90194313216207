.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  text-align: center;
}

.coming-soon__content {
  max-width: 600px;
}

.coming-soon__icon {
  font-size: 3rem;
  margin-bottom: 1.2rem;
}

.coming-soon__title {
  margin-left: -0.3rem;
}

.coming-soon__text {
  font-size: 0.83rem;
  color: #6c757d;
  margin-top: 0.8rem;
}