body {
	margin: 0;
	overflow-x: hidden;
}

.tablealign {
	display: flex;
	margin-top: -1rem;
	margin-left: 3rem;
}

.pillstyleout {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-top: 0.1rem;
	border-radius: 0.625rem;
	font-size: 0.688rem;
	width: 2.813rem;
	height: 1rem;
	font-weight: 700;
	margin-left: 0.3rem;
}

.pillstylein {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-top: 0.1rem;
	border-radius: 0.625rem;
	font-size: 0.688rem;
	width: 2rem;
	height: 1rem;
	font-weight: 700;
	margin-left: 0.3rem;
}

.tablegap {
	display: flex;
}

.vertical-divider {
	border-left: 1px solid #ccc; /* Adjust the color and style as needed */
	height: 40%;
	margin: 30px 30px; /* Adjust the margin as needed */
}

.split-pane {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.draggable-divider {
	height: 1.5rem;
	cursor: ns-resize; /* Use the resize cursor */
	position: relative;
	background-color: transparent;
}

.draggable-divider::before {
	content: "";
	height: 1px;
	width: 200rem;
	background-color: #ddd; /* Set the color of the line */
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.draggable-divider::after {
	content: "";
	background-image: url("../../.././public/assets/arrows.svg");
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0; /* Initially set to invisible */
	transition: opacity 0.3s ease; /* Add a smooth transition effect */
}

.draggable-divider:hover::after {
	opacity: 1;
}

.draggable-divider:hover::before {
	background-color: #ddd;
}

.tables-container {
	flex-grow: 1;
	/* overflow-y: auto;  */
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.order-table {
	flex-shrink: 0; /* Prevent OrderTable from growing */
}

.scrollable-content {
	height: 100vh;
	overflow: hidden;
}

ul {
	list-style: none;
}

/* li::before {
  content: './assets/greybullet.svg'; 
  display: inline-block; 
} */

.connection-line {
	position: relative;
}

.connection-line.has-divider::before {
	content: "";
	position: absolute;
	left: 1.15rem;
	top: 2.5rem;
	bottom: 0;
	width: 1px;
	background-color: #ccc; /* Adjust the color as needed */
	transform: translateX(-50%);
	height: 8rem;
}

.description {
	margin-left: 3.2rem;
	margin-top: -1.6rem;
}

/* .tables-container{
  margin-bottom: 1rem;
} */

.main-page-padding {
	padding-left: 1rem;
	padding-right: 1rem;
	min-width: 77.5rem;
}

.react-split__sash {
	background-color: #ccc;
}

.order-option-2 {
	margin-top: 1rem;
	border-top: 0.5px #ccc solid;
}

.order-option-2 .tablealign {
	flex-direction: column;
}

.order-option-2 .tablealign .vertical-divider {
	display: none;
}

.order-option-2 .connection-line.has-divider::before {
	height: calc(100% - 1.4rem);
	left: 1.3rem;
	top: 2.3rem;
}

.react-split__pane {
	overflow: auto;
}

.order-option-2.h-screen {
	height: 100vh;
}

.order-option-2 .split-pane {
	background-color: #f9f7ff;
	overflow: auto;
	height: 100%;
}

.order-option-2 .order-actions .action-icon:nth-child(3) {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.react-split {
	height: 100vh;
}

.order-option-2 .bullet-image {
	margin-top: 1.3rem;
	margin-left: 1rem;
	width: 10px;
}

.order-option-2 .main-page-padding {
	min-width: unset;
	padding-right: 0;
}

.order-option-2 .order-table .container {
	margin-bottom: 1rem;
	width: 50rem;
	gap: 1rem;
}

.order-option-2 .order-table .container .orderlabel {
	min-width: unset;
}

.order-option-2 .order-table .container div {
	flex-grow: 1;
}

.order-option-2 .order-table .container .orderlabel .buylabel,
.order-option-2 .order-table .container .orderlabel .selllabel {
	margin-left: 0;
	background-color: #f0f0f0;
	flex-grow: unset;
	display: flex;
	flex-direction: column;
}

.order-option-2 .order-table .container .orderlabel .buylabel,
.order-option-2 .order-table .container .orderlabel .selllabel,
.order-option-2 .order-table .container .orderlabel .shareflex {
	padding: 0.5rem 1rem;
}

.order-option-2 .order-table .container .orderlabel .buylabel > p,
.order-option-2 .order-table .container .orderlabel .selllabel > p,
.order-option-2 .order-table .container .orderlabel .shareflex > p {
	line-height: 1.23;
}

.order-option-2 .shareLabel,
.order-option-2 .lowerLabel {
	margin-left: 0;
}

.order-option-2 .flextableheaders {
	width: 100%;
	justify-content: flex-end;
	margin: 1rem;
	margin-right: 0;
}

.order-option-2 .flextableheaders .order-actions {
	position: sticky;
	right: 1rem;
}
