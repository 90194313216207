.calendar-recon .mantine-Input-input{
    background-color: #ece3ff;
    width: 13rem;
    border-radius: 0;
    text-align: left;
    border: 1px solid #ece3ff;
}

.calendar-recon .mantine-Select-input{
    background-color: #ece3ff;
    width: 13rem;
    border-radius: 0;
    text-align: left;
    border: 1px solid #ece3ff;
}

.calendar-recon-edit {
    background-color: #ece3ff;
    width: 13rem;
    border-radius: 0;
    text-align: left;
    border: 1px solid #ece3ff;
}

.calendar-recon-edit:focus,
.calendar-recon-edit:active {
    outline: none;
    border: 1px solid transparent; 
    background-color: #ece3ff; 
}