.presetText .mantine-Select-input {
	background-color: white;
	border-radius: 0;
	height: 34px;
	text-align: left;
	margin-bottom: 15px;
}
.presetText .mantine-Select-input:first-of-type {
	margin-top: 25px;
}
.presetText .mantine-TextInput-input {
	background-color: white;
	border-radius: 0;
	text-align: left;
}
.presetText .mantine-Select-input::placeholder {
	color: inherit;
	opacity: 1;
}

.presetText .mantine-Input-section {
	background: transparent;
	margin-left: 10px;
}

.custom-folder-icons-select .mantine-Select-leftSection {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.custom-folder-icons-select .folder-icon,
.custom-folder-icons-select .favorites-icon {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
}
.save-button:disabled {
	opacity: 0.4;
}
