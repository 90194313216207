.recent-presets .sub-heading {
  font-weight: 700;
}

.recent-presets ul {
  list-style: disc;
  text-decoration: underline;
  cursor: pointer;
}

.recent-presets .preset-link {
  color: #651fff;
  text-decoration: underline;
  cursor: pointer;
}

.recent-presets .recent-presets-title {
  margin-block-end: 0.75rem;
  font-size: 1.25rem;
}

.recent-presets-icon {
  color: #651fff;
}

.sub-heading {
  padding-top: 1.4rem;
  padding-left: 1rem;
}