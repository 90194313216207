.help {
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.action-item .ag-header-row {
  height: 1.875rem;
  min-height: 1.875rem;
  text-align: right;
  font-size: 0.688rem;
}

.action-item .ag-header-cell{
  padding-left: 0.5rem ;
}

.modal-user {
  width: 50rem;
}

.modal-approve .mantine-Modal-body {
  --modal-size: 31rem;
  background-color: #f7f9f9;
}

.modal .mantine-Modal-body {
  background-color: #f7f9f9;
}

.modal-fund-group .mantine-Modal-body {
  background-color: #f7f9f9;
  width: 34.375rem;
}

.textInput .mantine-Input-input {
  background-color: white;
  border-radius: 0.25rem;
  text-align: left;
  border: 1px solid white;
  font-size: 0.815rem;
  color: #5b5b5b;
  height: 2.5rem;
  width: 38.625rem;
}

.textInputRole .mantine-Select-input {
  background-color: white;
  border-radius: 0.25rem;
  text-align: left;
  border: 1px solid white;
  font-size: 0.815rem;
  color: #5b5b5b;
  height: 2.5rem;
  width: 17.5rem;
}

.textInput .mantine-Input-input:focus {
  border: solid 0.5px #7d7d7d;
}

.create-user-button {
  border-radius: 0.375rem;
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
}

.error-message {
  color: red;
  font-weight: bold;
  margin: 1.25rem;
}

.line-height-decrease {
  line-height: 1.4rem;
}

.multiline {
  padding: 0.5rem;
}

.textInput .mantine-TextInput-input[disabled] {
  background-color: #eee;
  color: #5b5b5b;
  font-size: 0.813rem;
  text-align: left;
}

.textInput .mantine-Select-option {
  justify-content: left;
  text-align: left;
}

.mantine-Checkbox-input {
  border: 1px groove #5b5b5b;
  border-radius: 0.188rem;
}

.line {
  width: 0.9375rem;
  height: 0.5px;
  margin: 1.21875rem 1.0625rem 1.25rem;
  background-color: #7d7d7d;
}

.selectedRow {
  color: transparent;
  position: fixed;
}

.mantine-ScrollArea-viewport:has(.dropdown-option) {
  padding-right: 0px;
}

.mantine-Select-option:has(.dropdown-option) {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  padding: 0.1rem;
}

.mantine-Select-option:hover:has(.dropdown-option),
.mantine-Select-option[data-combobox-active=true]:has(.chdropdown-optioneck) {
  background-color: #eee;
}

.role-modal .mantine-Modal-root {
  width: 38.0625;
  background-color: #f7f9f9;
}

.role-modal .mantine-Modal-body {
  padding: 0;
  background-color: #f7f9f9;
}

.role-modal .mantine-Modal-content .mantine-Paper-root {
  width: 38.0625rem;
  background-color: #fff;
}

.min-max-input .mantine-Input-input {
  height: 40px;
  background-color: #fff;
  text-align: left;
}

.role-input .mantine-Input-input {
  height: 40px;
  background-color: #fff;
  text-align: left;
}

.role-input label{
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
}

.role-modal .mantine-Checkbox-body{
  gap:0;
}

.action-item .ag-cell {
  padding-left: 0.45rem;
}
