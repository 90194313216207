.barLabel {
  margin-right: 36px !important;
  white-space: nowrap;
}

.notReadyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(2 * 29px); 
  justify-content: center; 
}

.notReadyText {
  margin: 0;
  line-height: 1.2;
}